@tailwind base;
@tailwind components;
@tailwind utilities;


/* Light mode styles */
@layer base {
  body {
    @apply bg-gray-200;
    /* Set light mode background color */
  }
}


/* Dark mode styles */
@layer utilities {
  .dark-mode {
    @apply dark:bg-dark-3;
    /* Set dark mode background color */
  }
}

.custom-link {
  @apply flex justify-center items-center flex-col ml-5 mr-5 no-underline text-inherit;
}

/* Loading icon animation */
.loading-icon {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dark-bg-color {
  background-color: #121212;
  color: #fff;
}



.bright-bg-color {
  background-color: #fff;
  color: #000;
}


/* My custom CSS */

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}